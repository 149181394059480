<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-table sticky-header="1000px" no-border-collapse responsive striped hover :current-page="currentPage"
                :per-page="perPage" :fields="membersfields" :items="filteredMembers" />
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col cols="auto">
              <b-pagination v-model="currentPage" :per-page="perPage" align="fill" size="sm" class="mt-2 mb-1" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
  },

  data() {
    return {
      filteredMembers: [
        {
          trade_id: 1111,
          amount: 1,
          side: 100000,
          price: 780000,
          status: 6000,
          commissions: 6000,
          date: 1111,
        },
        {
          trade_id: 1111,
          amount: 2,
          side: 200000,
          price: 45000,
          status: 85000,
          commissions: 85000,
          date: 1111,
        },
        {
          trade_id: 1111,
          amount: 3,
          side: 300000,
          price: 50000,
          status: 9500,
          commissions: 9500,
          date: 1111,
        },

      ],

      perPage: 10,
      currentPage: 1,
    }
  },
  computed: {
    membersfields() {
      return [
        { key: 'trade_id', label: this.$t('trade_id'), tdClass: 'nameOfTheClass' },
        { key: 'amount', label: this.$t('amount'), tdClass: 'nameOfTheClass' },
        { key: 'side', label: this.$t('side'), tdClass: 'nameOfTheClass' },
        { key: 'price', label: this.$t('price'), tdClass: 'nameOfTheClass' },
        { key: 'status', label: this.$t('status'), tdClass: 'nameOfTheClass' },
        { key: 'commissions', label: this.$t('commissions') },
        { key: 'date', label: this.$t('date') },
      ]
    },
  },

}
</script>
<style>
.nameOfTheClass {
  padding: 20px !important;
}
</style>
