<template>
  <div>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-card>
            <b-row
              align-v="center"
              align-h="between"
            >
              <b-col cols="12 mb-3">
                <h4>
                  {{ $t('clients') }}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="4"
                lg="3"
                xl="2"
              >
                <b-form-group
                  class="flex-shrink-0"
                  :label="$t('metatrader_account')"
                >
                  <b-form-input
                    id="search"
                    v-model="filter.metatrader_account"
                    :placeholder="$t('metatrader_account')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
                xl="2"
              >
                <b-form-group
                  class="flex-shrink-0"
                  :label="$t('symbol')"
                >
                  <b-form-input
                    v-model="filter.symbol"
                    :placeholder="$t('symbol')"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
                xl="3"
              >
                <b-form-group
                  class="flex-shrink-0"
                  :label="$t('from_time_execution')"
                >
                  <div class="d-flex">
                    <flat-pickr
                      ref="from-expiration-date"
                      v-model="filter.from_time"
                      class="form-control"
                      name="from-expiration"
                      :placeholder="$t('from_time_execution')"
                      autocomplete="nope"
                    />
                    <b-button @click="clearFromDate()">
                      <feather-icon
                        icon="XIcon"
                        class=""
                        size="15"
                      />
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="3"
                xl="3"
              >
                <b-form-group
                  class="flex-shrink-0"
                  :label="$t('to_time_execution')"
                >
                  <div class="d-flex">
                    <flat-pickr
                      ref="to-expiration-date"
                      v-model="filter.to_time"
                      class="form-control"
                      name="to-expiration"
                      :placeholder="$t('to_time_execution')"
                      autocomplete="nope"
                    />
                    <b-button @click="clearToDate()">
                      <feather-icon
                        icon="XIcon"
                        class=""
                        size="15"
                      />
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
                lg="12"
                xl="2"
              >
                <b-button
                  style="margin-top: 30px;"
                  class="w-100 mb-2 mb-d-0"
                  variant="success"
                  @click="search"
                >
                  {{ $t('search') }}
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  sticky-header="1000px"
                  no-border-collapse
                  responsive
                  striped
                  hover
                  :fields="membersfields"
                  :items="filteredMembers"
                >
                  <template #cell(created_at)="data">
                    <span>{{ getFormattedDate(data.item["created_at"]) }}</span>
                  </template>

                  <template
                    slot="bottom-row"
                    slot-scope="data"
                  >
                    <td />
                    <td />
                    <td />
                    <td>
                      <span class="font-weight-bolder">{{ $t('total-lot') }}: {{ total_lot }}</span>
                    </td>
                    <td>
                      <span class="font-weight-bolder">  {{ $t('total-commission') }}: {{ total_commission }}</span>
                    </td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="auto">
                <b-pagination
                  v-if="pagination.total > pagination.per_page"
                  v-model="pagination.page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  align="fill"
                  size="sm"
                  class="mt-2 mb-1"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormInput,
  BPagination,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import PannelIbApis from '@/modules/IB/services/apis'
import moment from '@/libs/moment'

const ib = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    flatPickr,
    BFormGroup,
    BFormInput,

  },

  data() {
    return {
      show: true,
      filteredMembers: [],
      currentpage: 1,
      total: 0,
      perPage: 5,
      pagination: {
        page: 1,
        per_page: 20,
        total: 0,
      },
      filter: {
        metatrader_account: '',
        symbol: '',
        from_time: '',
        to_time: '',
      },
      total_commission: '',
      total_lot: '',
    }
  },
  computed: {
    membersfields() {
      return [
        { key: 'order_id', label: this.$t('order_id') },
        { key: 'account', label: this.$t('metatrader_account') },
        { key: 'symbol', label: this.$t('symbol') },
        { key: 'lot', label: this.$t('lots') },
        { key: 'commission', label: this.$t('commissions') },
        { key: 'created_at', label: this.$t('date') },
      ]
    },
    page() {
      return this.pagination.page
    },
  },
  watch: {
    page(val) {
      if (val) {
        this.getData()
      }
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      const data = {
        ...(this.filter.metatrader_account && { account: this.filter.metatrader_account }),
        ...(this.filter.symbol && { symbol: this.filter.symbol }),
        ...(this.filter.from_time && { date_from: this.filter.from_time }),
        ...(this.filter.to_time && { date_to: this.filter.to_time }),
        ...this.pagination,
      }
      await ib.forexTradeHistory(this.$route.params.userID, data).then(res => {
        this.filteredMembers = res.data.results.data
        this.pagination.total = res.data.results.pagination.total
        if (res.data.results.data && res.data.results.data[0]) {
          this.total_commission = res.data.results.data[0].total_commission.toFixed(2)
          this.total_lot = res.data.results.data[0].total_lot.toFixed(2)
        }
      }).finally(() => {
        this.show = false
      })
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async search() {
      this.pagination.page = 1
      this.getData()
    },
    clearFromDate() {
      const datePickerWrap = this.$refs['from-expiration-date']
      if (!datePickerWrap) {
        return
      }
      datePickerWrap.fp.clear()
    },
    clearToDate() {
      const datePickerWrap = this.$refs['to-expiration-date']
      if (!datePickerWrap) {
        return
      }
      datePickerWrap.fp.clear()
    },
  },
}
</script>
<style>
.nameOfTheClass {
  padding: 20px !important;
}
</style>
