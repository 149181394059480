<template>
  <div>
    <IbClientTabs />
    <CryptoTraderHistory v-if="$route.params.market=='crypto'" />
    <ForexTraderHistory v-if="$route.params.market=='forex'" />
  </div>
</template>
<script>
import IbClientTabs from '../components/ibClientTabs.vue'
import CryptoTraderHistory from './cryptoTraderHistory.vue'
import ForexTraderHistory from './forexTraderHistory.vue'

export default {
  components: { IbClientTabs, CryptoTraderHistory, ForexTraderHistory },
}
</script>
